import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Layout from '../../components/Layout'
import { YellowButton } from '../../components/Global/Button'
import LeftWithImage from '../../components/Global/Hero/LeftWithImage'
import { InlineLink } from '../../components/Global/Link'
import SEO from '../../components/Global/SEO'
import { BelfastCityHall, TitanicBelfast, PeaceWall, ParliamentBuildings, QueensUniversity, CathedralQuarter } from '../../components/Tours/Locations'
import { ContentWrapper, InfoWithImageRight, SVGPattern, InfoWithImageLeft } from '../../components/Global/Content'
import SectionTitle from '../../components/Global/SectionTitle'
import { OffsetGridWrapper } from '../../components/Global/Content/OffsetGrid'

const BelfastCityTour = ({ location }) => {
  const {
    seoImg,
    titanic,
    belfaststreet,
    belfastcityhall
  } = useStaticQuery(graphql`
    query {
      seoImg: file(relativePath: { eq: "titanic-belfast.jpg" }) {
        childImageSharp {
          fixed(quality: 90, width: 1200, height: 630) {
            src
          }
        }
      }
      titanic: file(relativePath: { eq: "titanic-belfast.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 900, maxHeight: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      belfastcityhall: file(relativePath: { eq: "belfast-city-hall.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 980, maxHeight: 1140, cropFocus: CENTER) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      belfaststreet: file(relativePath: { eq: "belfast-streets-pubs.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 980, maxHeight: 1140, cropFocus: CENTER) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <Layout>

      <SEO
        title="Belfast City Tour | Titanic, City Hall, Belfast Murals, Stormont"
        description="Take a tour of Northern Ireland's capital city to discover the history and culture it has to offer as it moves on from it's troubled past."
        image={seoImg.childImageSharp.fixed.src}
        pathname={location.pathname}
      />

      <LeftWithImage
        image={titanic.childImageSharp.fluid}
        imageAlt="Titanic Building"
      >
        <div className="text-sm font-semibold uppercase tracking-wide text-gray-500 sm:text-base lg:text-sm xl:text-base">
          Private Tour
        </div>
        <h2 className="mt-1 text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:leading-none sm:text-6xl lg:text-5xl xl:text-6xl">
          Belfast City
          <br className="hidden md:inline" />
          <span className="text-yellow-300">Tour</span>
        </h2>
        <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
          Take a tour of Northern Ireland's capital city to discover the history and culture it has to offer as it moves on from it's troubled past.
        </p>
        <YellowButton to="/book-now" className="inline-block mt-8">Book Now</YellowButton>
      </LeftWithImage>

      <ContentWrapper>

        <SectionTitle 
          title="Discover Belfast City's Best Attractions"
          text={<>Tour in comfort with cost effective sightseeing of the magnificent City of Belfast, including the famous Titanic Building and Belfast Peace Wall, in one of Chauffeur Me's <InlineLink to="/our-fleet">luxury fleet</InlineLink> of vehicles.</>}
        />

        <InfoWithImageRight
          image={belfastcityhall.childImageSharp.fluid}
          imageAlt="Belfast City Hall at the center of Belfast"
        >
          <h4 className="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
            Historic City of Belfast
          </h4>
          <p className="mt-3 text-lg leading-7 font-semibold text-gray-500">
            Visit all the best tourist attactions in and around the City of Belfast with our Belfast City Tour.
          </p>
          <p className="mt-3 text-base leading-7 text-gray-500">
            Our dedicated and expert drivers will help you learn all about the history and culture of Belfast. Discover the City's historical buildings, beautiful architecture and learn of the past political divides.
          </p>
          <p className="mt-3 text-base leading-7 text-gray-500">
            Journey to the past and walk in the footsteps of Titanic's Builders, visit Sir Arthur Brumell Thomas' masterpiece, the Belfast City Hall or visit the Peace Wall, as stark reminder of the darkest period of Belfast's history.
          </p>
          <p className="mt-3 text-base leading-7 text-gray-500">
            Now is the time to visit; Belfast is as friendly as ever and the locals have even more than ever to share with you.
          </p>
        </InfoWithImageRight>

        <SVGPattern />

        <InfoWithImageLeft
          image={belfaststreet.childImageSharp.fluid}
          imageAlt="Belfast streets with pubs, bars and restaurants."
        >
          <h4 className="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
            Diverse Belfast Culture
          </h4>
          <p className="mt-3 text-lg font-semibold leading-7 text-gray-500">
            It's easy to overlook the culture of Belfast when you're bombarded by images of violence and religious clashing in the media.
          </p>
          <p className="mt-3 text-base leading-7 text-gray-500">
            Belfast is home to many galleries, opera houses, museums and movie theaters. You can also get your shop on, enjoy the many nightclubs, sample gourmet food or hang in one of the local pubs to get a true feel for the Belfast way of life. 
          </p>
          <p className="mt-3 text-base leading-7 text-gray-500">
            Enjoy all Belfast has to offer in the comfort of our luxury fleet of vehicles as you revel in the knowledge of our friendly and experienced drivers who are there for you every step of the way.
          </p>
        </InfoWithImageLeft>

      </ContentWrapper>

      <OffsetGridWrapper title="Belfast City Tour Locations">
        <BelfastCityHall />
        <TitanicBelfast />
        <PeaceWall />
        <ParliamentBuildings />
        <QueensUniversity />
        <CathedralQuarter />
      </OffsetGridWrapper>
    
    </Layout>
  )
}

export default BelfastCityTour